import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faTools } from "@fortawesome/free-solid-svg-icons"

export default function FabricationColumns(props) {
  return (
    <div>
      <div className="mx-auto py-12 px-4 max-w-6xl sm:px-6 lg:px-8 lg:py-24">
        <div className="flex flex-col items-center grid grid-cols-1 gap-12 lg:grid-cols-12 lg:gap-8">
          <div className="space-y-5 lg:col-span-7 sm:space-y-4">
            <h2 className="text-3xl font-extrabold tracking-tight text-blue sm:text-4xl">
              {props.header}
            </h2>
            <p className="text-xl text-gray-500">{props.subheader}</p>
          </div>
          <div className="lg:col-span-5">
            <ul className="space-y-2 sm:grid sm:grid-cols-2 sm:gap-4 sm:space-y-0 lg:gap-x-8">
              {props.items.map((item, i) => (
                <li key={i}>
                  <div className="flex items-center space-x-4 lg:space-x-6">
                    <FontAwesomeIcon
                      icon={faTools}
                      className="text-2xl text-yellow"
                    />
                    <div className="text-lg font-medium leading-6 space-y-1">
                      <p className="text-blue">{item}</p>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

import React, { useState } from "react"
import { graphql } from "gatsby"
import { getSrc } from "gatsby-plugin-image"
import Layout from "../components/Layout"
import Seo from "../components/Seo"
import FabricationColumns from "../components/FabricationColumns"
import Gallery from "../components/UI/Gallery"
import Modal from "../components/UI/Modal"

const fabricationItems = [
  "Skylight flashings",
  "Chimney caps",
  "Plenums",
  "Collars",
  "Sheet metal cans",
  "Rain Gutters",
  "Custom Fabrication",
]

// dont know what to do with this
// Options: Stainless Steel, Brass, or Copper

export default function FabricationPage({ data }) {
  const images = data.allFile.edges.map(({ node }) => {
    return {
      original: getSrc(node.childImageSharp.largeImage),
      thumbnail: getSrc(node.childImageSharp.thumbnail),
    }
  })
  const [open, setOpen] = useState(false)
  const handleClick = () => {
    setOpen(true)
  }
  return (
    <Layout>
      <Seo title="Fabrication" />
      <FabricationColumns
        header="Custom Fabrication"
        subheader="Here at Alpine we have our own fabrication shop that allows us to create absolutely anything you can dream of. We can custom tailor any job, just call with measurement details and lets see what we can do for you."
        items={fabricationItems}
      />
      <button
        onClick={handleClick}
        className="block mb-8 px-4 py-2 mx-auto border-2 bottom-4 hover:border-blue transition duration-100 ease-in hover:text-blue border-yellow text-blue"
      >
        View Gallery
      </button>
      <Modal open={open} setOpen={setOpen}>
        <Gallery images={images} />
      </Modal>
    </Layout>
  )
}

export const pageQuery = graphql`
  query MyQuery {
    allFile(filter: { relativeDirectory: { eq: "fab-gallery" } }) {
      edges {
        node {
          childImageSharp {
            largeImage: gatsbyImageData
            thumbnail: gatsbyImageData(width: 100, height: 60, layout: FIXED)
          }
        }
      }
    }
  }
`
